import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Plx from 'react-plx'
import { Toggle } from 'react-powerplug'
import styled from 'styled-components'
import HamburgerMenu from './HamburgerMenu'
import { Menu } from './menu'

// An array of parallax effects to be applied
const parallaxData = [
  {
    start: 0,
    end: 500,
    properties: [
      {
        startValue: 0,
        endValue: 150,
        property: 'translateY',
      },
    ],
  },
]

const Header = styled.header`
  width: 100%;
  z-index: ${props => (props.isOpen ? '10' : null)};
  padding: 1.5em ${props => props.theme.paddingWrapper.mobile};
  position: absolute;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 2.5em ${props => props.theme.paddingWrapper.desktop};
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`

const Logo = styled(Img)`
  width: 400px;
  max-width: 50vw;
  height: 100%;
  @media (orientation: landscape) and (max-width: ${props =>
      props.theme.responsive.medium}) {
    max-width: 30vw;
  }
`

const HeaderComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulConfiguracionGeneral {
          logo {
            title
            description
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const {
        fluid,
        title,
        description,
      } = data.contentfulConfiguracionGeneral.logo
      return (
        <Toggle>
          {({ on, toggle }) => (
            <Header isOpen={on}>
              <Wrapper>
                <HamburgerMenu isOpen={on} menuClicked={toggle} />
                <LogoContainer>
                  <Link to="/">
                    <Plx
                      className="MyAwesomeParallax"
                      parallaxData={parallaxData}
                    >
                      <Logo
                        fluid={fluid}
                        backgroundColor="transparent"
                        title={title}
                        alt={description}
                      />
                    </Plx>
                  </Link>
                </LogoContainer>
              </Wrapper>
              <Menu isOpen={on} toggle={toggle} />
            </Header>
          )}
        </Toggle>
      )
    }}
  />
)

export default HeaderComponent
