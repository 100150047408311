import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import Footer from './Footer'
import Header from './Header'

const Template = ({ children }) => (
  <div className="siteRoot">
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Header />
        <div className="siteContent">{children}</div>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
    <GlobalStyle />
  </div>
)

export default Template
