import PropTypes from 'prop-types'
import React from 'react'
import { Hover } from 'react-powerplug'
import styled from 'styled-components'

const Button = styled.button`
  position: fixed;
  padding: 8px 8px;
  border: 1px solid #979797;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 12px 12px;
  }
`

const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  transform: rotate(0deg);
`

const ButtonText = styled.span`
  font-size: 14px;
  position: absolute;
  bottom: 4px;
  left: 42px;
  text-decoration: ${props => (props.hovered ? 'underline' : 'none')};
  color: #4d4d4d;
  font-weight: bold;
`

const Shape = styled.div`
  opacity: ${props => (props.isOpen ? 0 : 1)};
  transition: opacity 0.25s ease-in-out;
  display: flex;
  height: 14px;
  width: 14px;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    height: 16px;
    width: 16px;
  }
  justify-content: space-between;
  position: absolute;
`

const ShapeLine = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
`

const ShapePoint = styled.span`
  width: 2px;
  height: 2px;
  background-color: #4d4d4d;
`

const HamburgerLine = ({ hovered, isOpen, style, value }) => {
  /*   if (((value === '2' && !hovered) || (value !== '2' && hovered)) && !isOpen) {
    return <span style={{ ...style, width: '75%' }} />
  } */
  if (!isOpen) return <span style={{ ...style, opacity: 0 }} />
  return <span style={{ ...style, width: '120%', left: '-2px', top: '-1px' }} />
}

export default function HamburgerMenu(props) {
  const { isOpen, strokeWidth, animationDuration } = props
  const width = `${props.width}px`
  const height = `${props.height}px`
  const halfHeight = `${parseInt(height.replace('px', ''), 10) / 2}px`
  const halfStrokeWidth = `-${strokeWidth / 2}px`

  const getTransformValue = (isReallyOpen, defaultPos, rotateVal) =>
    `translate3d(0,${isReallyOpen ? halfHeight : defaultPos},0) rotate(${
      isReallyOpen ? `${rotateVal}deg` : '0'
    })`

  const styles = {
    lineBase: {
      display: 'block',
      height: `${strokeWidth}px`,
      width: '100%',
      background: '#4d4d4d',
      transitionTimingFunction: 'ease',
      transitionDuration: `0.5s`,
      borderRadius: `0px`,
      transformOrigin: 'center',
      position: 'absolute',
      opacity: 1,
      // transition: 'opacity .25s ease-in-out',
    },
  }

  styles.firstLine = {
    ...styles.lineBase,
    transform: getTransformValue(isOpen, 0, 45),
  }
  styles.secondLine = {
    ...styles.lineBase,
    transitionTimingFunction: 'ease-out',
    transitionDuration: `${animationDuration / 4}s`,
    opacity: isOpen ? '0' : '1',
    top: halfHeight,
    marginTop: halfStrokeWidth,
  }

  const thirdLinePosition = `${props.height - 2}px`
  styles.thirdLine = {
    ...styles.lineBase,
    transform: getTransformValue(isOpen, thirdLinePosition, -45),
    // marginTop: halfStrokeWidth,
  }
  const lines = [
    {
      style: styles.firstLine,
      key: '1',
    },
    {
      style: styles.secondLine,
      key: '2',
    },
    {
      style: styles.thirdLine,
      key: '3',
    },
  ]
  return (
    <Hover>
      {({ hovered, bind }) => (
        <Button onClick={props.menuClicked} {...bind} isOpen={isOpen}>
          <Container width={width} height={height}>
            {lines.map(line => (
              <HamburgerLine
                key={line.key}
                value={line.key}
                style={line.style}
                hovered={hovered}
                isOpen={isOpen}
              />
            ))}
          </Container>
          <Shape isOpen={isOpen}>
            <ShapeLine>
              <ShapePoint />
              <ShapePoint />
              <ShapePoint />
            </ShapeLine>
            <ShapeLine>
              <ShapePoint />
              <ShapePoint />
              <ShapePoint />
            </ShapeLine>
            <ShapeLine>
              <ShapePoint />
              <ShapePoint />
              <ShapePoint />
            </ShapeLine>
          </Shape>
          <ButtonText hovered={hovered} />
        </Button>
      )}
    </Hover>
  )
}

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  menuClicked: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  animationDuration: PropTypes.number,
}
HamburgerMenu.defaultProps = {
  width: 16,
  height: 16,
  strokeWidth: 1,
  animationDuration: 2,
}
