export const menuItems = [
  {
    to: '/',
    text: 'Home',
  },
  {
    to: '/proyectos',
    text: 'Proyectos',
  },
  {
    to: '/nosotras',
    text: 'Nosotras',
  },
  {
    to: '/contacto',
    text: 'Contacto',
  },
]
