import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Box } from 'rebass'
import { menuItems } from './content'
import { Overlay, Nav, Element } from './menu.styled'

export const Menu = props => (
  <Overlay isOpen={props.isOpen}>
    <Box css={{ flex: 1 }} onClick={props.toggle} />
    <Nav isOpen={props.isOpen}>
      <nav>
        <ul>
          {menuItems.map(menuItem => (
            <Element key={menuItem.to}>
              <Link
                to={menuItem.to}
                // activeStyle={activeLinkStyle}
              >
                {menuItem.text}
              </Link>
            </Element>
          ))}
        </ul>
      </nav>
    </Nav>
  </Overlay>
)

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}
