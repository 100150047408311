const colors = {
  base: '#121212', // Black
  secondary: '#e9e9e9', // Medium Gray
  gray: '#4d4d4d',
  tertiary: '#f3f3f3', // Light Gray
  highlight: '#5b8bf7', // Light Blue
}

const theme = {
  colors,
  sizes: {
    maxWidthExtra: '1600px',
    maxWidthExtraInt: 1600,
    maxWidth: '1140px',
    maxWidthInt: 1140,
    maxWidthCentered: '1062px',
    maxWidthCenteredInt: 1062,
    maxWidthText: '640px',
  },
  responsive: {
    small: '40em',
    medium: '52em',
    large: '64em',
  },
  buttons: {
    primary: {
      color: colors.gray,
      backgroundColor: 'transparent',
      fontWeight: 500,
      fontSize: '80%',
      textTransform: 'uppercase',
      border: '1px solid #979797',
      paddingTop: '12px',
      paddingBottom: '12px',
      borderRadius: 0,
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        backgroundColor: colors.tertiary,
      },
    },
  },
  paddingWrapper: {
    mobile: '24px',
    desktop: '56px',
  },
  /* DEFAULT VALUES FROM styled-system */
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  /* ********************************* */
  fonts: {
    sans: 'Montserrat, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
}

export default theme
