import detectPassiveEvents from 'detect-passive-events'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import tweenFunctions from 'tween-functions'

const Container = styled.button`
  left: ${props => props.theme.paddingWrapper.mobile};
  right: auto;
  border: 1px solid #979797;
  width: 34px;
  height: 34px;
  bottom: 34px;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: 42px;
    height: 42px;
    left: ${props => props.theme.paddingWrapper.desktop};
    bottom: 72px;
  }
`

const Arrow = styled.span`
  border: solid #979797;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 6px;
  margin-top: 12px;
  transform: rotate(-135deg);
`

export class ScrollTop extends Component {
  data = {
    startValue: 0,
    currentTime: 0, // store current time of animation
    startTime: null,
    rafId: null,
  }

  state = { show: false }

  componentDidMount() {
    this.handleScroll() // initialize state

    // Add all listeners which can start scroll
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener(
      'wheel',
      this.stopScrolling,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    )
    window.addEventListener(
      'touchstart',
      this.stopScrolling,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.show !== this.state.show
  }

  componentWillUnmount() {
    // Remove all listeners which was registered
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('wheel', this.stopScrolling, false)
    window.removeEventListener('touchstart', this.stopScrolling, false)
  }

  handleScroll = () => {
    if (window.pageYOffset > this.props.showUnder) {
      if (!this.state.show) {
        this.setState({ show: true })
      }
    } else if (this.state.show) {
      this.setState({ show: false })
    }
  }

  handleClick = () => {
    this.stopScrolling()
    this.data.startValue = window.pageYOffset
    this.data.currentTime = 0
    this.data.startTime = null
    this.data.rafId = window.requestAnimationFrame(this.scrollStep)
  }

  scrollStep = timestamp => {
    if (!this.data.startTime) {
      this.data.startTime = timestamp
    }

    this.data.currentTime = timestamp - this.data.startTime

    const position = tweenFunctions[this.props.easing](
      this.data.currentTime,
      this.data.startValue,
      this.props.topPosition,
      this.props.duration
    )

    if (window.pageYOffset <= this.props.topPosition) {
      this.stopScrolling()
    } else {
      window.scrollTo(window.pageYOffset, position)
      this.data.rafId = window.requestAnimationFrame(this.scrollStep)
    }
  }

  stopScrolling = () => {
    window.cancelAnimationFrame(this.data.rafId)
  }

  render() {
    let { style } = this.props

    style = {
      ...style,
      ...ScrollTop.defaultProps,
    }
    style.opacity = this.state.show ? 1 : 0
    style.visibility = this.state.show ? 'visible' : 'hidden'
    style.transitionProperty = 'opacity, visibility'
    return (
      <Container onClick={this.handleClick} style={style}>
        <Arrow />
      </Container>
    )
  }
}

ScrollTop.defaultProps = {
  duration: 250,
  easing: 'easeOutCubic',
  style: {
    position: 'fixed',
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
  },
  topPosition: 0,
}

// Set validation property types
ScrollTop.propTypes = {
  topPosition: PropTypes.number,
  showUnder: PropTypes.number.isRequired, // show button under this position,
  easing: PropTypes.oneOf([
    'linear',
    'easeInQuad',
    'easeOutQuad',
    'easeInOutQuad',
    'easeInCubic',
    'easeOutCubic',
    'easeInOutCubic',
    'easeInQuart',
    'easeOutQuart',
    'easeInOutQuart',
    'easeInQuint',
    'easeOutQuint',
    'easeInOutQuint',
    'easeInSine',
    'easeOutSine',
    'easeInOutSine',
    'easeInExpo',
    'easeOutExpo',
    'easeInOutExpo',
    'easeInCirc',
    'easeOutCirc',
    'easeInOutCirc',
    'easeInElastic',
    'easeOutElastic',
    'easeInOutElastic',
    'easeInBack',
    'easeOutBack',
    'easeInOutBack',
    'easeInBounce',
    'easeOutBounce',
    'easeInOutBounce',
  ]),
  duration: PropTypes.number, // seconds
  style: PropTypes.objectOf(PropTypes.string),
}
