import React from 'react'
import { Box, Text } from 'rebass'
import styled from 'styled-components'

const StyledParagraph = styled(Text)`
  text-align: justify;
`

export const Paragraph = props => (
  <StyledParagraph lineHeight="1.5" py={3} {...props} />
)

export const BoxText = styled(Box)`
  p {
    margin: ${props => props.theme.space[2]}px 0;
    line-height: 1.4;
    text-align: justify;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      line-height: 1.6;
    }
  }
`

export const H1 = styled.h1`
  font-size: 44px;
  font-weight: 300;
  color: #4d4d4d;
  text-transform: uppercase;
`
