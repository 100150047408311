import React from 'react'
import { Box } from 'rebass'
import styled from 'styled-components'
import theme from '../../styles/theme'

export const FullHeight = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(Box)`
  max-width: ${props => props.theme.sizes.maxWidth};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding-top: 35vh;
  }
`

export const Container = props => (
  <StyledContainer
    px={[theme.paddingWrapper.mobile, theme.paddingWrapper.desktop]}
    pt={['20vh', '25vh']}
    pb="2em"
    width="100%"
    mx="auto"
    mt={0}
    mb="auto"
    {...props}
  />
)
