import { Box } from 'rebass'
import styled from 'styled-components'

export const Nav = styled(Box)`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  z-index: ${props => (props.isOpen ? 10 : -10)};
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.tertiary};
  background: linear-gradient(to bottom, #f1f1f1 0%, #e5e5e5 88%, #d5d5d5 100%);
  max-width: 50vw;
  padding: 100px ${props => props.theme.paddingWrapper.mobile} 0;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    max-width: 260px;
    padding: 160px ${props => props.theme.paddingWrapper.desktop} 0;
  }
  box-shadow: -4px 0px 32px 0px rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out;
  transition: opacity 0.4s ease-out 0s, transform 0s linear 0s,
    -webkit-transform 0s linear 0s;
`

export const Element = styled.li`
  margin-bottom: 18px;
  a {
    text-decoration: none;
    color: #979797;
    font-weight: 300;
    font-size: 18px;
    transition: all 0.4s;
    // border-bottom: 2px solid #979797;
    text-transform: uppercase;
    &:hover {
      color: #d0cccc;
      border-color: #d0cccc;
      // letter-spacing: 3px;
    }
  }
`

export const Overlay = styled.div`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  z-index: ${props => (props.isOpen ? 1 : -1)};
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s ease-in-out;
  transition: opacity 0.4s ease-out 0s, transform 0s linear 0s,
    -webkit-transform 0s linear 0s;
`
